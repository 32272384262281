import React, { useEffect, useState } from "react";
import "./Header.css";
import AddNew from "../../assets/header/addNew.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu from "../../assets/menu.svg"
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true); // Ensure the sidebar is open on desktop view
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const toggleSidebar = () => {
  //   if (isMobile) {
  //     setIsSidebarOpen(!isSidebarOpen);
  //   }
  // };

  const openModal = () => {
    navigate("/layout/createform");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
  }, [searchQuery]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="header">
      {isMobile && (
        <button onClick={toggleSidebar} className="mobile-toggle-button">
          <img src={menu} width={35} height={35}></img>
        </button>
      )}
      <div className="left-side">Welcome, Mr.Prem!</div>
      <div className="right-side">
        <div className="search-field">
          <button className="add-button" onClick={openModal}>
            <img src={AddNew} alt="plus" />
          </button>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {searchQuery.trim() !== "" && (
            <div className="additional-content">
              {searchResults.length > 0 ? (
                <div className="search-results">
                  {/* {searchResults.map((result, index) => (
                    <div key={index}>{result}</div>
                  ))} */}
                </div>
              ) : (
                <div className="card-search">
                  <div className="card-body-search">
                    <h5 className="card-title-search" style={{ color: "black" }}>
                      No results available
                    </h5>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
