import React, { useState, ChangeEvent } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Papa from 'papaparse';

interface TextpopupModalProps {
  show: boolean;
  handleClose: () => void;
}

const Textpopupmodal: React.FC<TextpopupModalProps> = ({ show, handleClose }) => {
  const [textInput, setTextInput] = useState('');
  const [csvData, setCsvData] = useState<any[][]>([]);

  const handleTextInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(e.target.value);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (result: { data: any[] }) => {
          console.log(result.data); // Log the parsed data to inspect it
          const updatedData = result.data.map((row: any) => {
            if (Array.isArray(row)) {
              return [...row, textInput];
            } else if (typeof row === 'object' && row !== null) {
              return { ...row, newColumn: textInput };
            } else {
              console.warn("Row is neither array nor object", row);
              return row;
            }
          });
          setCsvData(updatedData);
        },
        header: true,
      });
    }
  };

  const downloadSampleCSV = () => {
    const sampleData = [
      ['Customer Name', 'Company Name', 'phone no'],
      ['william', 'dell', '934567821'],
      ['smith', 'wipro', '95257689054']
    ];
    const csv = Papa.unparse(sampleData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sample.csv';
    link.click();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFile" className="my-3">
          <Form.Label>Upload CSV</Form.Label>
          <Form.Control type="file" accept=".csv" onChange={handleFileUpload} />
        </Form.Group>
        {/* <Form.Group controlId="textInput">
          <Form.Label>Text Input</Form.Label>
          <Form.Control as="textarea" rows={3} value={textInput} onChange={handleTextInputChange} />
        </Form.Group> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save
        </Button>
        <Button variant="success" onClick={downloadSampleCSV}>Download Sample</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Textpopupmodal;
