import React from "react";
import { useState } from "react";
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, plugins } from 'chart.js';
import Increase from "../../assets/dashboard/increase.svg";
import Decrease from "../../assets/dashboard/decrease.svg";
import ViewLead from "../../assets/dashboard/viewLead.svg";
import TotalSubscribers from "../../assets/dashboard/totalSubscribers.svg";
import HotLeads from "../../assets/dashboard/hotLeads.svg";
import WarmLeads from "../../assets/dashboard/warmLeads.svg";
import ColdLeads from "../../assets/dashboard/coldLeads.svg";
import Meeting from "../../assets/dashboard/meeting.svg";
import Calls from "../../assets/dashboard/calls.svg";
import revenue from "../../assets/dashboard/revenueicon.svg"
import overdeuicon from "../../assets/dashboard/overdueicon.svg"
import dealsclosed from "../../assets/dashboard/dealsclosedicon1.svg"
import dealscreated from "../../assets/dashboard/dealscreatedicon1.svg"
import PendingDeals from "../../assets/dashboard/pendingDeals.svg";
import TodayDeals from "../../assets/dashboard/todayDeals.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Admindasnboard.css';
import ReactApexChart from 'react-apexcharts';
import { Table } from "react-bootstrap";
import TableHeader from "cdbreact/dist/components/Table/TableHeader";
// import TableBody from "cdbreact/dist/components/Table/TableBody";
import { MdPadding } from "react-icons/md";
import dropicon from '../../assets/dashboard/dropdownforadmin.svg'
// Register the required components for ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Admindashboard = () => {
    const [selectedItem, setSelectedItem] = useState('All')
    const [selectedtime, setSelectedtime] = useState('Yearly')
    const [selectedbartime,setSelectedbartime]=useState('Yearly')

    const handletime = (item: React.SetStateAction<string>) => {
        setSelectedtime(item)
    }
    const handlebartime = (item: React.SetStateAction<string>) => {
        setSelectedbartime(item)
    }
        const handleSelect = (item: React.SetStateAction<string>) => {
            setSelectedItem(item);
        };

        function getPercentageChange(currentValue: number, previousValue: number) {
            if (previousValue === 0) {
              return currentValue.toFixed(0); 
            }
            const change =
              ((currentValue - previousValue));
            return change.toFixed(0);
    }

        const barchartdata = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: '',
                data: [60, 50, 90, 40, 60, 70, 45, 55, 65, 75, 80, 90, 100],
                backgroundColor: 'rgba(195, 255, 228, 1)',
                borderRadius: 12,
                borderColor: 'rgba(195, 255, 228, 1)',
                hoverBackgroundColor: 'rgba(13, 137, 65, 1)',
                hoverBorderColor: 'rgba(13, 137, 65, 1)',
                borderSkipped: 'middle' as const,
                // barThickness:42
                            }]
        };
        const barchartoptions = {
            responsive: true,
            plugins: {
                tooltip: {
                  enabled: false,
                  external: function (context: any) {
                    let tooltipEl = document.getElementById("chartjs-tooltip");
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      tooltipEl.innerHTML = "<table></table>";
                      document.body.appendChild(tooltipEl);
                    }
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = "0";
                      return;
                    }
                    tooltipEl.classList.remove("above", "below", "no-transform");
                    if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                      tooltipEl.classList.add("no-transform");
                    }
          
                    function getBody(bodyItem: any) {
                      return bodyItem.lines;
                    }
          
                    if (tooltipModel.body) {
                      const titleLines = tooltipModel.title || [];
                      const bodyLines = tooltipModel.body.map(getBody);
                      const dataset = context.chart.data.datasets[0];
                      const data = dataset.data;
                      const index = context.tooltip.dataPoints[0].dataIndex;
                      let innerHtml = "<tbody>";
          
                      bodyLines.forEach(function (body: any, i: any) {
                        let style = "background:" + "rgba(0, 0, 0, 1)";
                        style += "; border-color:" + "rgba(0, 0, 0, 0)";
                        style += "; border-radius: 10px";
                        style += "; padding: 10px";
                        style += "; font-size: 12px";
                        style += "; font-weight: 500";
                        style += "; letter-spacing: 0.5px";
                        style += "; color:" + "rgba(255, 255, 255, 1)";
                        style += "; margin: none";
                        const value = body[0].split(": ")[1];
                        const currentValue = data[index];
                        const previousValue = data[index - 1] || 0;
                        const percentageChange = getPercentageChange(
                          currentValue,
                          previousValue
                        );
                        const absoluteChange = Math.abs(parseInt(percentageChange)); 
                        const icon =
                          parseInt(percentageChange) >= 0 ? Increase : Decrease;
                        const imageSrc = `<img src="${icon}" alt="Increase Icon" style="width:20px;height:20px;" />`;
                        const span = `<span style="${style}">${imageSrc} ${absoluteChange}%</span>`;
                        innerHtml += "<tr><td>" + span + "</td></tr>";
                      });
                      innerHtml += "</tbody>";
          
                      let tableRoot = tooltipEl.querySelector("table");
                      if (tableRoot) {
                        tableRoot.innerHTML = innerHtml;
                      }
                    }
          
                    const position = context.chart.canvas.getBoundingClientRect();
                    const bodyFont =tooltipModel.options.bodyFont;
          
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      tooltipEl.innerHTML = "<table></table>";
                      document.body.appendChild(tooltipEl);
                    }
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.position = "absolute";
                    // tooltipEl.style.border = "false";
                    tooltipEl.style.left =
                      position.left +
                      window.pageXOffset +
                      tooltipModel.caretX -
                      48 +
                      "px";
                    tooltipEl.style.top =
                      position.top + window.pageYOffset + tooltipModel.caretY - 50 + "px";
                    tooltipEl.style.font = bodyFont.string;
                    tooltipEl.style.padding =
                      tooltipModel.padding + "px " + tooltipModel.padding + "px";
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.border = "none";
                    tooltipEl.style.backgroundColor = "none";
                  },
                },
                legend: {
                  display: false,
                },
              },

            scales: {
                x: {
                    grid: { display: false },
                    categoryPercentage: 0.9,  // Adjust this value to control the space between bars

                },
                y: {
                    display: true,
                    grid: { display: false },
                }
            },
        };
        const doughnutData = {
            labels: ['Cold calls ', 'Social Media', 'Referral', 'Advertisement'],
            datasets: [
                {
                    label: 'Lead Sources',
                    data: [15, 40, 25, 20],
                    backgroundColor: [
                        'rgba(0, 191, 165, 1)',
                        'rgba(0, 121, 107, 1)',
                        'rgba(175, 180, 43, 1)',
                        'rgba(56, 142, 60, 1)'
                    ],
                    borderColor: [
                        'rgba(255,255,255)',
                        'rgba(255,255,255)',
                        'rgba(255,255,255)',
                        'rgba(255,255,255)'
                    ],
                    borderRadius: 10,
                    spacing: 14,
                    weight: 15,
                    rotation: 335,
                    hoverOffset: 3,
                    borderDashOffset: 200,
                    borderWidth: 1,
                },
            ],
        };
        const doughnutOptions = {
            cutout: '65%',
            responsive: true,
            labels: {
                label: 'label',
                align: 'center' as const,
            },
            plugins: {
                tooltip: {
                  enabled: false,
                  external: function (context: any) {
                    let tooltipEl = document.getElementById("chartjs-tooltip");
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      tooltipEl.innerHTML = "<table></table>";
                      document.body.appendChild(tooltipEl);
                    }
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = "0";
                      return;
                    }
                    tooltipEl.classList.remove("above", "below", "no-transform");
                    if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                      tooltipEl.classList.add("no-transform");
                    }
          
                    function getBody(bodyItem: any) {
                      return bodyItem.lines;
                    }
          
                    if (tooltipModel.body) {
                      const titleLines = tooltipModel.title || [];
                      const bodyLines = tooltipModel.body.map(getBody);
                      const dataset = context.chart.data.datasets[0];
                      const data = dataset.data;
                      const index = context.tooltip.dataPoints[0].dataIndex;
                      const labels = context.chart.data.labels;
                      let innerHtml = "<tbody>";
          
                      bodyLines.forEach(function (body: any, i: any) {
                        let style = "background:" + "#FFFFFF";
                        style += "; border-radius: 12px";
                        // style += "; padding: 10px";
                        style += "; font-size: 14px";
                        style += "; font-weight: 650";
                        style += "; font-family: 'Nunito'";
                        style += "; letter-spacing: 0.5px";
                        style += "; color:" + "#000000";
                        style += "; width:" + "max-width";
                        style += "; height:" + "max-width";
                        style += "; border: none";
                        
                        const value = body[0].split(": ")[1];
                        const currentValue = data[index];
                        const currentLabel = labels[index];
                        const span = `<span style="${style}">${currentValue}% <br> ${currentLabel}</span>`;
                        innerHtml += "<tr><td>" + span + "</td></tr>";
                      });
                      innerHtml += "</tbody>";
          
                      let tableRoot = tooltipEl.querySelector("table");
                      if (tableRoot) {
                        tableRoot.innerHTML = innerHtml;
                      }
                    }
          
                    const position = context.chart.canvas.getBoundingClientRect();
                    const bodyFont =tooltipModel.options.bodyFont;
          
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      tooltipEl.innerHTML = "<table></table>";
                      document.body.appendChild(tooltipEl);
                    }
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.fontWeight = "800";
                    tooltipEl.style.left =
                      position.left +
                      window.pageXOffset +
                      tooltipModel.caretX -
                      100 +
                      "px";
                    tooltipEl.style.top =
                      position.top + window.pageYOffset + tooltipModel.caretY - 40 + "px";
                    tooltipEl.style.font = bodyFont.string;
                    tooltipEl.style.padding =
                      tooltipModel.padding + "px " + tooltipModel.padding + "px";
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.border = "0px";
                    tooltipEl.style.backgroundColor = "#FFFFFF";
                  },
                },
                legend: {
                    display: true,
                    position: 'right' as const,
                    color: "rgb(0,0,0)",
                    labels: {
                        usePointStyle: true, // Use circle points instead of squares
                        boxWidth: 5, // Adjust the size of the legend box
                        padding: 40,
                        font: {
                            size: 13,
                            color: "rgb(0,0,0,0)" // Use custom style for legend items
                        },
                    },
                },
                cutoutPercentage: 50,
                spacing: 50,
              },


        };
        const semidoughnutData1 = {
            labels: ['Cold calls ', 'Social Media', 'Referral', 'Advertisement'],
            datasets: [
                {
                    label: 'Lead Sources',
                    data: [85.71, 14.29],
                    backgroundColor: [
                        'rgba(0, 138, 125, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],
                    borderColor: [
                        'rgba(0, 138, 125, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],

                    spacing: 3,
                    weight: 1,
                    hoverOffset: 4,
                    borderDashOffset: 200,
                    borderWidth: 1,
                    rotation: 265,
                    circumference: 188
                },
            ],
        };
        const semidoughnutOptions1 = {
            // responsive: true,
            cutout: '70%',
            plugins: {
                cutoutPercentage: 50,
                spacing: 50,
                label: {
                    render: 'label',
                    position: 'inside',
                    arc: true,
                },
                labels: {
                    display: true,
                    position: 'center' as const // Position label in the center
                },
                legend: {
                    display: false,
                    position: 'right' as const,
                    labels: {
                        usePointStyle: true, // Use circle points instead of squares
                        boxWidth: 20, // Adjust the size of the legend box
                        padding: 40,
                        font: {
                            size: 15,
                            color: "rgb(0,0,0,0)" // Use custom style for legend items
                        },
                    },
                },
            },

        };
        const semidoughnutData2 = {
            labels: ['Cold calls ', 'Social Media', 'Referral', 'Advertisement'],
            datasets: [
                {
                    label: 'Lead Sources',
                    data: [50.87, 49.13],
                    backgroundColor: [
                        'rgba(0, 94, 5, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],
                    borderColor: [
                        'rgba(0, 94, 5, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],
                    spacing: 3,
                    weight: 1,
                    hoverOffset: 4,
                    borderDashOffset: 200,
                    borderWidth: 1,
                    rotation: 265,
                    circumference: 188
                },
            ],
        };
        const semidoughnutOptions2 = {
            // responsive: true,
            cutout: '70%',
            plugins: {
                cutoutPercentage: 50,
                spacing: 50,
                label: {
                    display: true,
                    position: 'middle' as const
                },
                legend: {
                    display: false,
                    position: 'right' as const,
                    labels: {
                        usePointStyle: true, // Use circle points instead of squares
                        boxWidth: 20, // Adjust the size of the legend box
                        padding: 40,
                        font: {
                            size: 15,
                            color: "rgb(0,0,0,0)" // Use custom style for legend items
                        },
                    },
                },
            },

        };
        const semidoughnutData3 = {
            labels: ['Cold calls ', 'Social Media', 'Referral', 'Advertisement'],
            datasets: [
                {
                    label: 'Lead Sources',
                    data: [25.71,74.29],
                    backgroundColor: [
                        'rgba(73, 91, 41, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],
                    borderColor: [
                        'rgba(73, 91, 41, 1)',
                        'rgba(54, 67, 78, 1)'
                    ],
                    spacing: 3,
                    weight: 1,
                    hoverOffset: 4,
                    borderDashOffset: 200,
                    borderWidth: 1,
                    rotation: 265,
                    circumference: 188
                },
            ],
        };
        const semidoughnutOptions3 = {
            // responsive: true,
            cutout: '70%',
            plugins: {
                cutoutPercentage: 50,
                spacing: 50,
                label: {
                    display: true,
                    position: 'middle' as const
                },
                legend: {
                    display: false,
                    position: 'right' as const,
                    labels: {
                        usePointStyle: true, // Use circle points instead of squares
                        boxWidth: 20, // Adjust the size of the legend box
                        padding: 40,
                        font: {
                            size: 15,
                            color: "rgb(0,0,0,0)" // Use custom style for legend items
                        },
                    },
                },
            },

        };

        return (
            <div className="container pt-5 pb-5">
            <div className="admindasnboard">
                <div className="card-container-admin">
                    <>
                        {/* Deals Created This Month */}
                        <div className="card container-admin">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Deals Created This <br /> Month</span>
                                    <img
                                        src={dealscreated}
                                        alt="Total Leads"
                                        className="leads-icon"
                                    />
                                </div>
                                <h1 className="card-info">150</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">8.5%</span>Up from Last year
                                </span>
                            </a>
                        </div>
                        {/* Deal Closing This Month */}
                        <div className="card container-admin">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Deal Closing This<br /> Month</span>
                                    <img src={dealsclosed} alt="Hot leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">45</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">1.3%</span> Up from Last year
                                </span>{" "}
                            </a>
                        </div>
                        {/* Revenue This Month */}
                        <div className="card container-admin">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Revenue This Month</span>
                                    <img src={revenue} alt="Warm Leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">254</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">8.5%</span> Up from Last year
                                </span>{" "}
                            </a>
                        </div>
                        {/* Overdue Task */}
                        <div className="card container-admin">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Overdue Task</span>
                                    <img src={overdeuicon} alt="Cold Leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">5</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">1.3%</span> Up from last year
                                </span>{" "}
                            </a>
                        </div>
                    </>
                </div>
                <div className="lead-summery-card mb-4">
                    <p className="summery-para" style={{ fontSize: "20px" }}>Lead Summary</p>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle dropdown-toggle-lead" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="dropdowntextemployee" style={{ fontSize: "12px",fontWeight:"600" }}>{selectedItem}</span>
                            <img src={dropicon} alt="Dropdown Icon" height={24} width={32.04} />
                        </button>
                        <ul className="dropdown-menu">
                            <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handleSelect('Employee 1')}>Employee 1</button></li>
                            <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handleSelect('Employee 2')}>Employee 2</button></li>
                            <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handleSelect('All')}>All</button></li>
                        </ul>
                    </div>
                </div>
                <div className="card-container-admin">
                    <>
                        {/* Total Leads */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Total Leads</span>
                                    <img
                                        src={TotalSubscribers}
                                        alt="Total Leads"
                                        className="leads-icon"
                                    />
                                </div>
                                <h1 className="card-info">150</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">8.5%</span>Up from Last year
                                </span>
                            </a>
                        </div>
                        {/* Hot Leads */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Hot Leads</span>
                                    <img src={HotLeads} alt="Hot leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">45</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">1.3%</span> Up from Last year
                                </span>{" "}
                            </a>
                        </div>
                        {/* Warm Leads */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Warm Leads</span>
                                    <img src={WarmLeads} alt="Warm Leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">254</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">8.5%</span> Up from Last year
                                </span>{" "}
                            </a>
                        </div>
                        {/* Cold Leads */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Cold Leads</span>
                                    <img src={ColdLeads} alt="Cold Leads" className="leads-icon" />
                                </div>
                                <h1 className="card-info">5</h1>
                                <span className="percentage-container">
                                    <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                                    <span className="percentage">1.3%</span> Up from last year
                                </span>{" "}
                            </a>
                        </div>
                        {/* Meeting */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Meetings</span>
                                    <img src={Meeting} alt="Meetings" className="leads-icon" />
                                </div>
                                <h1 className="card-info">08</h1>
                            </a>
                        </div>
                        {/* Calls */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Calls</span>
                                    <img src={Calls} alt="Calls" className="leads-icon" />
                                </div>
                                <h1 className="card-info">17</h1>
                            </a>
                        </div>
                        {/* Today deals */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Today Deals</span>
                                    <img src={TodayDeals} alt="Today Deals" className="leads-icon" />
                                </div>
                                <h1 className="card-info">01</h1>
                            </a>
                        </div>
                        {/* Pending deals */}
                        <div className="card">
                            <a style={{ color: "black", textDecoration: "none" }}>
                                <div className="card-details">
                                    <span className="card-title">Pending Deals</span>
                                    <img
                                        src={PendingDeals}
                                        alt="Pending Deals"
                                        className="leads-icon"
                                    />
                                </div>
                                <h1 className="card-info">05</h1>
                                <span className="percentage-container"></span>{" "}
                            </a>
                        </div>
                    </>
                </div>
                <div className="overview-chart">
                    <div className="overview-card">
                        <div className="cardheader b-0">
                            <div className="overview-card-header pt-2 pb-2">
                                <p style={{ fontSize: "22px", fontWeight: "500", margin: "inherit", color: "#000000" }}>Overview</p>
                                <div className="dropdown overview-dropdown" style={{ border: "none" }}>
                                    <button className="btn-yearly btn-secondary dropdown-toggle dropdown-overview" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none" }}>
                                    <span className="dropdowntext"style={{fontSize:"12px",fontWeight:"400"}}>{selectedbartime}</span>
                                                <img src={dropicon} alt="Dropdown Icon"  width={24} height={24}/>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handlebartime('Weekly')}>Weekly</button></li>
                                                <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handlebartime('Monthly')}>Monthly</button></li>
                                                <li className="listitem"> <button className="dropdown-item" type="button" onClick={() => handlebartime('Yearly')}>Yearly</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="overview-card-head-text mb-8"><p className="overviewcardhead">Converted Leads</p></div>
                        </div>
                        <div className="card-body pt-4  overview-card-bodyadmin">
                            <Bar style={{ height: "410px" }} data={barchartdata} options={barchartoptions} />
                        </div>
                    </div>
                </div>
                <div className="leadandsummerymanage">
                    <div className="row leadsource-summery">
                        <div className="col-md-7  col-sm-6 leadsource">
                            <div className="card-leadsource-card">
                                <div className="cardheader b-0">
                                    <div className="leadsource-card-header" style={{ padding: "0% 3% 0% 3%" }}>
                                        <p style={{ fontSize: "22px", fontWeight: "600" }}>Leads by Source</p>
                                        <div className="dropdown overview-dropdown" style={{ border: "none" }}>
                                            <button className="btn-yearly btn-secondary dropdown-toggle dropdown-overview" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none" }}>
                                                <span className="dropdowntext"style={{fontSize:"12px",fontWeight:"400"}}>{selectedtime}</span>
                                                <img src={dropicon} alt="Dropdown Icon" height={24} width={24}/>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handletime('Weekly')}>Weekly</button></li>
                                                <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handletime('Monthly')}>Monthly</button></li>
                                                <li className="listitem"><button className="dropdown-item" type="button" onClick={() => handletime('Yearly')}>Yearly</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-body leadsource-card-body">
                                        <div className="center_text">
                                            <p> <span className="" style={{ color: "black", fontWeight: "980", opacity: "60%" }}>Lead</span> <span style={{ color: "rgba(32, 34, 36, 1)" }}>Source</span> </p>
                                        </div>
                                        <Doughnut style={{ height: "470px",width:"460px" }} data={doughnutData} options={doughnutOptions} />
                                        {/* <div className="chart-container">
                                        <h2>Leads by Source</h2>
                                        <div className="svg-container">
                                            <svg viewBox="0 0 36 36" className="circular-chart">
                                                <path className="circle social-media" stroke-dasharray="28, 64" stroke-dashoffset="25" d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                <path className="circle referral" stroke-dasharray="22.5, 77.5" stroke-dashoffset="61" d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                <path className="circle advertisement" stroke-dasharray="20, 82" stroke-dashoffset="88.5" d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                <path className="circle cold-call" strokeDasharray="13.5, 86.5" strokeDashoffset="111.5" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"/>
                                            </svg>
                                            <div className="center-text">Lead Source</div>
                                        </div>
                                        <div className="chart-labels">
                                            <div className="label cold-call">15% Cold Call</div>
                                            <div className="label social-media">40% Social Media</div>
                                            <div className="label referral">25% Referral</div>
                                            <div className="label advertisement">20% Advertisement</div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6">
                            <div className="card lead-summery" style={{ height: "auto" }}>
                                <div className="card-header lead-summeryheader" style={{ border: "none" }}>
                                    <p style={{ fontSize: "22px", fontWeight: "600" }}>Pending Lead Summery</p>
                                </div>
                                <div className="card-body  lead-summerybody" style={{ display: "flex" }}>
                                    <div className="row pending-status">
                                        <div className="col-md-12 pending-status" style={{ textAlign: "center" }}>
                                            <p style={{ fontSize: "16px", margin: "none", marginBottom: "-30px", fontWeight: "500" }}>Lead Pending Status</p>
                                            <div className="pending-statuschart" style={{ height: "30vh", width: "30vh" }}>
                                                <div className="bottom_text1">
                                                    <p> <span className="" style={{ color: "black", fontWeight: "900", }}>85.71%</span></p>
                                                </div>
                                                <Doughnut data={semidoughnutData1} options={semidoughnutOptions1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pending-status-response">
                                        <div className="col-md-6 col-lg-6  col-sm-6 pending-status-noresponse" style={{ height: "20vh", width: "50%" }}><p style={{ fontSize: "15.79px", marginBottom: "-10px", fontWeight: "500" }}>No Response</p>
                                            {/* <ReactApexChart options={options} series={series} type="donut" /> */}
                                            <div className="bottom_text2">
                                                <p> <span className="" style={{ color: "black", fontWeight: "700" }}>50.87%</span></p>
                                            </div>
                                            <Doughnut data={semidoughnutData2} options={semidoughnutOptions2} />
                                        </div>
                                        <div className="col-md-6 col-lg-6  col-sm-6 pending-status-notreachable" style={{ height: "20vh", width: "50%" }}><p style={{ fontSize: "15.79px", marginBottom: "-10px", fontWeight: "500" }}>Not Reachable</p>
                                            {/* <ReactApexChart options={options} series={series} type="donut" /> */}
                                            <div className="bottom_text3">
                                                <p> <span className="" style={{ color: "black", fontWeight: "700" }}>25.71%</span></p>
                                            </div>
                                            <Doughnut data={semidoughnutData3} options={semidoughnutOptions3} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row card-rows">
                    <div className="col-md-4 top10col">
                        <div className="top10coldesign">
                            <div className="card top10-cards">
                                <div className="header-top10-card">
                                    <p>Top 10 Deals Of This Month</p>
                                </div>
                                <div className="body cradgroup">
                                    {/* <div className="crd-group"><div className="card-top10">
                                    <div className="row-deals">
                                        <div className="col-md-12-deals">
                                            <p>Deal Name</p>
                                            <p>Deal Value</p>
                                        </div>
                                    </div>
                                    <div className="row-deals">
                                        <div className="col-md-12-deals">
                                            <p className="mb-0">1.Suresh</p>
                                            <p className="mb-0">₹ 50000</p>
                                        </div>
                                    </div>
                                </div></div> */}
                                <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 top10closedcol">
                        <div className="top10coldesign">
                            <div className="card top10-closed">
                                <div className="header-top10-closed">
                                    <p>Top 10 closed Deals Of This Month</p>
                                </div>
                                <div className="body cradgroup">
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pendingcol">
                        <div className="top10coldesign">
                            <div className="card pending-deals">
                                <div className="header-pending-deals">
                                    <p>Pending Deals Lost This Month</p>
                                </div>
                                <div className="body cradgroup">
                                    <div className="card-group">
                                        <div className="card top10cardsgroup">
                                            <div className="card-header top10cardsgroup-header">
                                                <span className="cardgroup-text">Deal Name</span>
                                                <span className="cardgroup-text">Deal Value</span>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="card-body top10cardsgroup-body">
                                                <span className="cardgroup-text">1.Suresh</span>
                                                <span className="cardgroup-text1">₹ 50000</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="useractivityreport">
                    <div className="heading"><p style={{ fontSize: "22px", padding: "0% 0% 3% 0%", margin: "0px", fontWeight: "600" }}>User Activity Report</p></div>
                    <div className="userreporttable" >
                        <table className="table table-bordered table-admindashboard" >
                            <thead>
                                <tr>
                                    <th colSpan={2} style={{ color: "black", borderColor: "#ccc", fontSize: "20px" }}>User Name</th>
                                    <th colSpan={1} style={{ background: "#2E7D32", color: "#FFFFFF", fontSize: "20px" }}>New Leads</th>
                                    <th colSpan={4} style={{ background: "#299204", color: "#FFFFFF", fontSize: "20px" }}>Scheduled</th>
                                    <th colSpan={4} style={{ background: "#00897B", color: "#FFFFFF", fontSize: "20px" }}>Completed</th>
                                </tr>
                                <tr>
                                    <th colSpan={2} style={{borderColor:" #ccc"}}></th>
                                    <th colSpan={1} style={{borderColor:" #ccc"}}></th>
                                    <th colSpan={2} style={{ background: "#299204", color: "#FFFFFF", fontSize: "14.29px" }}>Meeting Fixed</th>
                                    <th colSpan={2} style={{ background: "#299204", color: "#FFFFFF", fontSize: "14.29px" }}>Call Fixed</th>
                                    <th colSpan={2} style={{ background: "#00897B", color: "#FFFFFF", fontSize: "14.29px" }}>Meeting Done</th>
                                    <th colSpan={2} style={{ background: "#00897B", color: "#FFFFFF", fontSize: "14.29px" }}>Call Done</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colSpan={2} style={{textAlign:"left"}}>Shaliya N</th>
                                    <th colSpan={1}>05</th>
                                    <th colSpan={2}>04</th>
                                    <th colSpan={2}>01</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>00</th>
                                </tr>
                                <tr>
                                    <th colSpan={2} style={{textAlign:"left"}}>Prem Nazeer</th>
                                    <th colSpan={1}>15</th>
                                    <th colSpan={2}>11</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>00</th>
                                </tr>
                                <tr>
                                    <th colSpan={2} style={{textAlign:"left"}}>Elamathi</th>
                                    <th colSpan={1}>01</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>01</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>00</th>
                                </tr>
                                <tr>
                                    <th colSpan={2} style={{textAlign:"left"}}>JayaSurya</th>
                                    <th colSpan={1}>45</th>
                                    <th colSpan={2}>20</th>
                                    <th colSpan={2}>12</th>
                                    <th colSpan={2}>00</th>
                                    <th colSpan={2}>00</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        )
    }

    export default Admindashboard
