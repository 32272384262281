import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import Layout from "./Layout";
// import Login from "./component/login/login1d";
import Login from "./component/login/login";
import Dashboard from "./component/page/Dashboard";
import Admindashboard from "./component/page/Admindashboard";
import CreateForm from "./component/page/CreateForm";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "rsuite/dist/rsuite.min.css";
import { CustomProvider, Container } from "rsuite";
import Leads from "./component/page/Leads";
import AddLead from "./component/page/AddLead";

function App() {
  return (
    <CustomProvider theme="light">
      <Container className="app">
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/layout/*"
                element={<PrivateRoute element={<Layout />} />}
              >
                <Route index path="admindashboard" element={<Admindashboard />} />
                <Route index path="dashboard" element={<Dashboard />} />
                <Route index path="createform" element={<CreateForm />} />
                <Route index path="leads" element={<Leads />} />
                <Route index path="add-lead" element={<AddLead />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Container>
    </CustomProvider>
  );
}

export default App;
