import React from "react";
import "./AddLead.css";
import { Button } from "react-bootstrap";
// import Close from "../../assets/add-lead/close.svg";

const data = [
  "Twitter",
  "Facebook",
  "Instagram",
  "WhatsApp",
  "Referral",
  "LinkedIn",
].map((item) => ({ label: item, value: item }));

const AddLead = () => {
  const handleKeyPress = (event: any) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className="addlead">
      <div className="lead-title">
        <span>New Lead</span>
        <img
          // src={Close}
          style={{ width: "20px", height: "20px" }}
          alt="close-lead"
        />
      </div>
      <div className="lead-form">
        <div className="leadformfields">
          <label htmlFor="name">Full Name*</label>
          <input className="addleadinput"
            type="text"
            id="name"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
              handleKeyPress(e);
            }}
            required
            maxLength={50}
          />
        </div>
        <div className="leadformfields">
          <label htmlFor="company">Company Name*</label>
          <input
          className="addleadinput"
            type="text"
            id="company"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
              handleKeyPress(e);
            }}
            required
            maxLength={50}
          />
        </div>
        <div className="leadformfields">
          <label htmlFor="phone">Phone Number</label>
          <input className="addleadinput" type="tel" id="phone" maxLength={10} minLength={10} />
        </div>
        <div className="leadformfields">
          <label htmlFor="lead">Lead Source*</label>
          <select id="lead" required>
            {data.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="leadformfields">
          <label htmlFor="email">Email</label>
          <input className="addleadinput" type="email" id="email" maxLength={50} />
        </div>
        <div className="leadformfields">
          <label htmlFor="lead">Lead Status*</label>
          <select id="lead" required>
            {data.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="leadformfields">
          <label htmlFor="enquiry">Enquiry</label>
          <input className="addleadinput" type="text" id="enquiry" maxLength={250} />
        </div>
        <div className="leadformfields">
          <label htmlFor="enquiry">Full Address*</label>
          <textarea id="address" maxLength={250} />
        </div>
      </div >
      <div className="btnleadnew">
      <button className="newlead-btn">Submit</button>
      </div>
    </div>
  );
};

export default AddLead;
