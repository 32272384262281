import React, { useState } from "react";
import "./Leads.css";
import { Dropdown } from "rsuite";
import { Table, TagPicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import ExcelUploadModal from "./Popupmodal";
import TextpopupModal from "./Textpopupmodal";
import dropicon from '../../assets/dashboard/dropdownforadmin.svg';
import search from "../../assets/search.svg";
import LeadTable from "./Leadtable";

interface LeadData {
  date: string;
  customername: string;
  leadsource: string;
  companyname: string;
  leadstatus: string;
  mobilenumber: string;
  email: string;
}

const defaultColumns = [
  { key: "date", label: "date", width: 150 },
  { key: "customername", label: "Customer Name", width: 210 },
  { key: "leadsource", label: "Lead Source", width: 123 },
  { key: "companyname", label: "Company Name", width: 200 },
  { key: "leadstatus", label: "Lead Status", flexGrow: 1 },
  { key: "mobilenumber", label: "Mobile Number", flexGrow: 1 },
  { key: "email", label: "E-mail", flexGrow: 1 }
];

const data: LeadData[] = [
  {
    date: "2024-06-30",
    customername: "John Doe",
    leadsource: "Website",
    companyname: "Company A",
    leadstatus: "New",
    mobilenumber: "1234567890",
    email: "john@example.com",
  },
  {
    date: "2024-06-29",
    customername: "Jane Smith",
    leadsource: "Referral",
    companyname: "Company B",
    leadstatus: "Contacted",
    mobilenumber: "0987654321",
    email: "jane@example.com",
  },
  {
    date: "2024-06-30",
    customername: "John Doe",
    leadsource: "Website",
    companyname: "Company A",
    leadstatus: "New",
    mobilenumber: "1234567890",
    email: "john@example.com",
  },
  {
    date: "2024-06-29",
    customername: "Jane Smith",
    leadsource: "Referral",
    companyname: "Company B",
    leadstatus: "Contacted",
    mobilenumber: "0987654321",
    email: "jane@example.com",
  },
  {
    date: "2024-06-30",
    customername: "John Doe",
    leadsource: "Website",
    companyname: "Company A",
    leadstatus: "New",
    mobilenumber: "1234567890",
    email: "john@example.com",
  },
  {
    date: "2024-06-29",
    customername: "Jane Smith",
    leadsource: "Referral",
    companyname: "Company B",
    leadstatus: "Contacted",
    mobilenumber: "0987654321",
    email: "jane@example.com",
  },
  {
    date: "2024-06-30",
    customername: "John Doe",
    leadsource: "Website",
    companyname: "Company A",
    leadstatus: "New",
    mobilenumber: "1234567890",
    email: "john@example.com",
  },
  {
    date: "2024-06-29",
    customername: "Jane Smith",
    leadsource: "Referral",
    companyname: "Company B",
    leadstatus: "Contacted",
    mobilenumber: "0987654321",
    email: "jane@example.com",
  },
];

const { Column, HeaderCell, Cell } = Table;
const CompactCell = (props: any) => <Cell {...props} style={{ padding: 4 }} />;
const CompactHeaderCell = (props: any) => (
  <HeaderCell {...props} style={{ padding: 4 }} />
);

const Leads = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [showTextModal, setShowTextModal] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(true);
  const [compact, setCompact] = React.useState(true);
  const [bordered, setBordered] = React.useState(true);
  const [droptextlead, setDroptextlead] = useState("Overdue");
  const [columnKeys, setColumnKeys] = React.useState(
    defaultColumns.map((column) => column.key)
  );
  const [searchFilters, setSearchFilters] = useState<{ [key: string]: string }>({});

  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const CustomCell = compact ? CompactCell : Cell;
  const CustomHeaderCell = compact ? CompactHeaderCell : HeaderCell;

  const addLead = (e: any) => {
    e.preventDefault();
    navigate("/layout/add-lead");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // file change logic here
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const csvData = e.target.result as string;
        const importedData = csvData.split('\n').map(row => row.split(','));
        const headers = importedData[0];
        const filteredData = importedData.slice(1).map((row: any[]) => {
          const filteredRow: Partial<LeadData> = {};
          columnKeys.forEach((key, index) => {
            const columnIndex = headers.findIndex(header => header.trim() === key);
            if (columnIndex !== -1 && row[columnIndex] !== undefined) {
              filteredRow[key as keyof LeadData] = row[columnIndex];
            }
          });
          return filteredRow;
        });
        console.log("Filtered Data:", filteredData);
      }
    };
    reader.readAsText(file);
    setShowModal(false);
  };

  const downloadSampleExcel = () => {
    const filteredData = data.slice(0, 4).map((item) => {
      const filteredItem: Partial<LeadData> = {};
      columnKeys.forEach((key) => {
        filteredItem[key as keyof LeadData] = item[key as keyof LeadData];
      });
      return filteredItem;
    });

    const csvData = convertToCSV(filteredData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'SampleData.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const convertToCSV = (data: any[]): string => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map((obj) => Object.values(obj).join(',') + '\n').join('');
    return header + rows;
  };

  const handlenotes = () => {
    setShowTextModal(true); // Show the Textpopupmodal when clicking on "Import Note"
  };

  const upload = () => {
    setShowModal(true);
  };
  const handleleaddrptext = (item: React.SetStateAction<string>) => {
    setDroptextlead(item);
  };

  const handleSearchChange = (key: string, value: string) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  const filteredData = data.filter((row) =>
    Object.keys(searchFilters).every((key) =>
      row[key as keyof LeadData]
        .toString()
        .toLowerCase()
        .includes(searchFilters[key].toLowerCase())
    )
  );

  return (
    <div className="leads">
      <div className="lead-action">
        <Dropdown title="Create Lead" className="create">
          <Dropdown.Item onClick={addLead}>New Lead</Dropdown.Item>
          <Dropdown.Item onClick={upload}>Import Lead</Dropdown.Item>
          <Dropdown.Item onClick={handlenotes}>Import Note</Dropdown.Item>
        </Dropdown>
        <ExcelUploadModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleFileChange={handleFileChange}
          handleFileUpload={handleFileUpload}
          downloadSampleExcel={downloadSampleExcel}
        />
        <TextpopupModal
          show={showTextModal}
          handleClose={() => setShowTextModal(false)}
        />
        <Dropdown title="Action">
          <Dropdown.Item>Mass Delete</Dropdown.Item>
          <Dropdown.Item>Mass Update</Dropdown.Item>
          <Dropdown.Item>Mass Email</Dropdown.Item>
          <Dropdown.Item>Export Lead</Dropdown.Item>
          <Dropdown.Item>Bulk Assign</Dropdown.Item>
        </Dropdown>
      </div>
      <div className="lead-table">
        <TagPicker
          data={defaultColumns}
          labelKey="label"
          valueKey="key"
          value={columnKeys}
          onChange={setColumnKeys}
          cleanable={false}
        />
        <hr />
        <div style={{ height: "auto" }}>
          <div className="headertexttable">
            <div className="leadtablegrp">
              <span className="leadtablefont">Leads</span>
              <div className="tabletextfields">
                <div className="row leadsearchrow">
                  <div className="col-md-2 leadicon">
                    <img src={search} alt="search" height={24} width={24}></img>
                  </div>
                  <div className="col-md-10 leadsearchinputfield">
                    <input type="search" placeholder="Search" className="leadsearch" />
                  </div>
                </div>
                <div className="dropdown lead-dropdown">
                  <button className="btn-yearly btn-secondary dropdown-toggle dropdown-lead" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none" }}>
                    <span className="dropdownleadtext">{droptextlead}</span>
                    <img src={dropicon} alt="Dropdown Icon" height={20} width={20} />
                  </button>
                  <ul className="dropdown-menu">
                    <li className="listitem">
                      <button className="dropdown-item" type="button" onClick={() => handleleaddrptext('1 option')}>1 option</button>
                    </li>
                    <li className="listitem">
                      <button className="dropdown-item" type="button" onClick={() => handleleaddrptext('2 option')}>2 option</button>
                    </li>
                    <li className="listitem">
                      <button className="dropdown-item" type="button" onClick={() => handleleaddrptext('overdue')}>overdue</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Table
            height={300}
            showHeader={showHeader}
            data={noData ? [] : filteredData}
            bordered={bordered}
            cellBordered={bordered}
            headerHeight={compact ? 30 : 40}
            rowHeight={compact ? 30 : 46}
          >
            <Column width={defaultColumns[0].width} key="search-row">
              <HeaderCell children={undefined}></HeaderCell>
              <Cell>
                {({ rowData, rowIndex, ...rest }) => (
                  <div>
                    {columns.map((column) => (
                      <input
                        key={column.key}
                        type="search"
                        placeholder={`Search ${column.label}`}
                        value={searchFilters[column.key] || ''}
                        onChange={(e) => handleSearchChange(column.key, e.target.value)}
                        style={{ width: column.width }}
                      />
                    ))}
                  </div>
                )}
              </Cell>
            </Column>
            {columns.map((column) => {
              const { key, label, ...rest } = column;
              return (
                <Column {...rest} key={key}>
                  <CustomHeaderCell>{label}</CustomHeaderCell>
                  <CustomCell dataKey={key} />
                </Column>
              );
            })}
          </Table>
        </div>
      </div>
      <div>
        {/* Pass data and columnKeys to LeadTable */}
        {/* <LeadTable data={data} columnKeys={defaultColumns.map(col => col.key)} />*/}
      </div>
    </div>
  );
};

export default Leads;
