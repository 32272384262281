import React from 'react';

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileUpload: (file: File) => void;
  downloadSampleExcel: () => void;
}

const ExcelUploadModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  handleFileChange,
  handleFileUpload,
  downloadSampleExcel,
}) => {
  const handleUpload = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      handleFileUpload(fileInput.files[0]);
    } else {
      alert('Please select a file to upload.');
    }
  };

  return (
    <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex={-1} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Import CSV file *</h5>
            <button type="button" className="close" aria-label="Close" onClick={() => setShowModal(false)}>
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <input id="fileInput" type="file" className="control-file-csv" onChange={handleFileChange} required style={{alignItems:"center",top:"5px"}} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleUpload}>Upload</button>
            <button type="button" className="btn btn-success" onClick={downloadSampleExcel}>Download Sample</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcelUploadModal;
