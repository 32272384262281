import React, { useEffect } from 'react'
import { useState } from 'react'
// import { CardBody, CardFooter, CardHeader, Col, Row } from 'bootstrap'
// import Container from 'react-bootstrap/Container'
// import Card from ''
import zeraleads from '../../assets/zera leads3-01.png'
import './login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
// import validator from 'Validator'
import { useNavigate } from 'react-router-dom'
import { error } from 'console'
import { BsPersonFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaGoogle } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import $, { event } from 'jquery';
import { MdEmail } from "react-icons/md"
import { useAuth } from '../../context/AuthContext';
import { url } from 'inspector';
import usernamelogo from '../../assets/signin/Username.png'
import emaillogo from '../../assets/signin/email1.svg'
import namelogo from '../../assets/signin/Name.png'
import passwordlogo from '../../assets/signin/Password.png'

const Login = () => {
    const navigate = useNavigate()
    const { login } = useAuth();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailerror, setEamilError] = useState(false)
    const [passworderror, setPasswordError] = useState(false)
    const [loginshow, setLoginshow] = useState(true)
    const [firstName, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [signupemail, setSignupEmail] = useState("")
    const [signuppassword, setSignupPassword] = useState("")
    const [Confirmpassword, setConfirmpassword] = useState("")
    const [signupconformpassword, setSignupconformpassword] = useState("")
    const [firstNameerror, setFirstnameerror] = useState(false)
    const [lastnameerror, setLastnameerror] = useState(false)
    const [signupemailerror, setSignupEmailerror] = useState(false)
    const [signuppassworderror, setSignupPassworderror] = useState(false)
    const [signupconformpassworderror, setSignupconformpassworderror] = useState(false)
    const [passwordmatch, setPasswordMatch] = useState(false)

    useEffect(() => {
        $('.optionbtn2').on('click', function () {
            $('.optionbtn1').removeClass('active');
            $(this).addClass('active ,card');
        });

        $('.optionbtn1').on('click', function () {
            $('.optionbtn2').removeClass('active');
            $(this).addClass('active');
        });

        return () => {
            $('.optionbtn2').off('click');
            $('.optionbtn1').off('click');
        };
    }, []);

    const handleChange = (e: any) => {
        console.log("name", e.target.name, e.target.value);

        if (e.target.name === "email") {
            setEmail(e.target.value);
            const ValidInput = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(
                e.target.value
            );
            const endsWithValidDomain =
                e.target.value.endsWith(".com") ||
                e.target.value.toLowerCase().endsWith(".in");
            if (!ValidInput && !endsWithValidDomain && e.target.value.length > 0) {
                setEamilError(true);
                return;
            } else {
                setEamilError(false);
            }
        }
        else if (e.target.name === "signupuemail") {
            setSignupEmail(e.target.value);
            const ValidInput = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(
                e.target.value
            );
            const endsWithValidDomain =
                e.target.value.endsWith(".com") ||
                e.target.value.toLowerCase().endsWith(".in");
            if (!ValidInput && !endsWithValidDomain && e.target.value.length > 0) {
                setSignupEmailerror(true);
                console.log("sig em er")
            } else {
                setSignupEmailerror(false);
                console.log("sig em no er")
            }
        } else if (e.target.name === "password") {
            setPassword(e.target.value)
            const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
                e.target.value
            );
            if (!ValidInput) {
                setPasswordError(true);
                console.error("Invalid input. Please enter a valid password.");
                return;
            } else {
                setPasswordError(false);
            }
        } else if (e.target.name === "signuppassword") {
            setSignupPassword(e.target.value)
            const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
                e.target.value
            );
            if (!ValidInput) {
                setSignupPassworderror(true);
                console.error("Invalid input. Please enter a valid password.");
                return;
            } else {
                setSignupPassworderror(false);
                if (e.target.value !== Confirmpassword) {
                    setPasswordMatch(true);
                } else {
                    setPasswordMatch(false);
                }
            }
        } else if (e.target.name === "confirm_password") {
            setSignupconformpassword(e.target.value)
            console.log("y")
            const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
                e.target.value
            );
            if (!ValidInput) {
                setSignupconformpassworderror(true);
                console.error("Invalid input. Please enter a valid password.");
                return;
            } else {
                setSignupconformpassworderror(false);
                if (e.target.value !== signuppassword) {
                    setPasswordMatch(true);
                } else {
                    setPasswordMatch(false);
                }

            };

        }
        else if (e.target.name === "FirstName") {
            setFirstname(e.target.value)
            const ValidINPUT = /^[a-zA-Z ]+$/.test(e.target.value);
            console.log(e.target.value)
            if (!ValidINPUT) {
                setFirstnameerror(true);
                console.error("Invalid input. Please enter only characters.");
                return;
            } else {
                setFirstnameerror(false);
            }
        }
        else if (e.target.name === "LastName") {
            setLastname(e.target.value)
            console.log("conpass")
            const ValidINPUT = /^[a-zA-Z ]+$/.test(e.target.value);
            console.log(e.target.value)
            if (!ValidINPUT) {
                setLastnameerror(true);
                console.error("Invalid input. Please enter only characters.");
                return;
            } else {
                setLastnameerror(false);
            }
        } else if (signuppassword.length === Confirmpassword.length) {
            if (signuppassword !== Confirmpassword) {
                setPasswordMatch(true);
            }
            else {
                setPasswordMatch(false);
            }

        }

    }


    const handlesubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        login();
        setTimeout(() => {
        navigate("/layout/admindashboard");
        }, 1000);
    }

    const handlelogin = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        setLoginshow(true)
    }
    const handlesignin = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        setLoginshow(false)

    }

    return (
        <div className='formcontainer nunito-family' style={{ height: "100vh" }}>
            <form className='signformad pt-0'>
                <div className='row des-view nunito-family' style={{ height: "100vh" }}>
                    <div className='col-md-4 des-view-col nunito-family'>
                        <div className='option btn-lg nunito-family'>
                            <button className="optionbtn1 active nunito-family" style={{
                                border: "none",
                                backgroundColor: "transparent",
                                display: 'inline-block',
                                borderRadius: "32px",
                                width: "200px",
                                height: "60px",
                                fontSize: "18px",
                                paddingRight: "100px",
                                color:"white"

                            }}
                                onClick={handlelogin}

                            >SIGN IN
                            </button>
                            <button className="optionbtn2 btn-lg nunito-family" style={{
                                border: "none",
                                borderRadius: "32px", backgroundColor: "transparent",
                                width: "200px",
                                height: "60px",
                                fontSize: "18px",
                                paddingRight: "100px",
                                color:'white'
                            }}
                                onClick={handlesignin}
                            >SIGN UP
                            </button>

                        </div>
                    </div>
                    <div className='col-md-8 nunito-family' style={{ color: "white", padding: "0px" }}>
                        {loginshow === true ? <div className='card-login nunito-family' style={{
                            margin: " 0px",
                            border: "0px",
                        }}>
                            <div className='CardHeader nunito-family' style={{background:"white"}}>
                                <img src={zeraleads} alt='logo' style={{ width: "200px", height: "200px" }}></img>
                            </div>
                            <div className='card-body nunito-family'>
                                {/* <div className='input-fields'> */}
                                <div className='username nunito-family'>
                                    <span><img src={usernamelogo}/></span>{" "}
                                    <input
                                        name="email"
                                        value={email}
                                        onChange={(e) => handleChange(e)}
                                        placeholder='Email'
                                        style={{ border: "none", borderBottom: "none", outline: "none", fontSize: "16px" }}
                                        required
                                    ></input>
                                </div>
                                <p style={{ fontSize: "12px", color: "red" }}>{emailerror ? "Enter Valid Email" : ""}</p>
                                <div className='userpassword nunito-family' style={{ paddingTop: "20px" }}>
                                    <span><img src={passwordlogo} /></span>{" "}
                                    <input
                                        name="password"
                                        value={password}
                                        onChange={(e) => handleChange(e)}
                                        placeholder='Password'
                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                        required
                                    ></input>
                                </div>
                                <p style={{ fontSize: "12px", color: "red" }}>{passworderror ? "Enter Valid Password" : ""}</p>
                                <div className='row rows-btn nunito-family' style={{ height: "30px", marginTop: "10px", marginLeft: "2px" }}>
                                    <div className='col-md-12 nunito-family' style={{ fontSize: "15px", color: "limegreen" }}>
                                        <p ><a>Forgot Password?</a></p>
                                    </div>

                                    <div className='col-md-12 nunito-family' style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center", fontSize: "16px"
                                    }}>
                                        <button className='formbtn btn-lg nunito-family' onClick={handlesubmit} style={{ display: "grid", justifyContent: "center", alignItems: "center" }}>SIGN IN</button>
                                    </div>

                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                            :
                            <div className='card nunito-family' style={{
                                margin: " 0px",
                                border: "0px",
                                width: "100%",
                                padding: "4.4rem 0rem 3.8rem 0rem"
                            }}>
                                <div className='CardHeader-mob nunito-family' style={{ display: "grid", justifyContent: "center",background:"white" }}>
                                    <img src={zeraleads} alt='logo' style={{ width: "200px", height: "200px" }}></img>
                                </div>
                                <div className='card-body-signup nunito-family'>
                                    <div className='signupdetails' style={{
                                        placeItems: "center",

                                    }}>
                                        <div className='row nunito-family' style={{ paddingBottom: "6%" }}>
                                            <div className="col-md-6 nunito-family" style={{ alignItems: "center" }}>
                                                <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray" }}>
                                                    <span><img src={namelogo} /></span>{" "}
                                                    <input
                                                        name="FirstName"
                                                        className='form_input nunito-family'
                                                        value={firstName}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder='First Name'
                                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                                    />
                                                </div>
                                                <p style={{ fontSize: "12px", color: "red" }}>{firstNameerror ? "Enter Valid First Name" : ""}</p>
                                            </div>

                                            <div className="col-md-6 nunito-family" style={{ alignItems: "center" }}>
                                                <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray" }}>
                                                    <span><img src={namelogo} /></span>{" "}
                                                    <input
                                                        name="LastName"
                                                        className='form_input nunito-family'
                                                        value={lastname}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder='Last Name'
                                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                                        required
                                                    />
                                                </div>
                                                <p style={{ fontSize: "12px", color: "red" }}>{lastnameerror ? "Enter Valid Last name" : ""}</p>
                                            </div>
                                        </div>
                                        <div className='row nunito-family'>
                                            <div className='col-md-12 nunito-family' style={{ paddingRight: "0px" }}>
                                                <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray", marginRight: "2%" }}>
                                                    <span><img src={emaillogo} height={21} width={21}/></span>{" "}
                                                    <input
                                                        name="signupuemail"
                                                        className='form_input nunito-family'
                                                        value={signupemail}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder='Email'
                                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                                        required
                                                    />
                                                </div>
                                                <p style={{ fontSize: "12px", color: "red" }}>{signupemailerror ? "Enter Valid Email" : ""}</p>
                                            </div>
                                        </div>
                                        <div className='row siuppassword nunito-family' style={{ paddingTop: "6%", paddingBottom: "6%" }}>
                                            <div className='col-md-6 nunito-family' style={{ alignItems: "center" }}>
                                                <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray" }}>
                                                    <span><img src={passwordlogo} /></span>{" "}
                                                    <input
                                                        name="signuppassword"
                                                        className='form_input nunito-family'
                                                        value={signuppassword}
                                                        minLength={8}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder='Password'
                                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                                        required
                                                    />
                                                </div>
                                                <p style={{ fontSize: "12px", color: "red" }}>{passwordmatch ? "Password Does Not Matched" : ""}</p>
                                                <p style={{ fontSize: "12px", color: "red" }}>{signuppassworderror ? "Enter Valid Password" : ""}</p>
                                            </div>
                                            <div className='col-md-6 nunito-family' style={{ alignItems: "center" }}>
                                                <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray" }}>
                                                    <span><img src={passwordlogo} /></span>{" "}
                                                    <input
                                                        name="confirm_password"
                                                        minLength={8}
                                                        className='form_input nunito-family'
                                                        value={signupconformpassword}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder='Confirm Password'
                                                        style={{ border: "none", outline: "none", fontSize: "16px" }}
                                                        required
                                                    />
                                                </div>
                                                <p style={{ fontSize: "12px", color: "red" }}>{passwordmatch ? "Password Does Not Matched" : ""}</p>
                                                <p style={{ fontSize: "12px", color: "red" }}>{signupconformpassworderror ? "Enter Valid Confirm password" : ""}</p>
                                            </div>
                                        </div>


                                        <div className='checkbox acceptterms nunito-family' style={{ paddingBottom: "6%" }}>
                                            <label style={{ alignItems: "center", display: "flex", textAlign: "center", justifyContent: "center" }}><span style={{alignItems:"center",display:"flex"}}><input
                                                type="checkbox" /> agree to the Terms of Service and Privacy Notice </span></label>
                                        </div>
                                        <div className='row nunito-family'>
                                            <div className='col-md-12 nunito-family' style={{ display: "flex", placeContent: "center" }}>
                                                <button className='signup-btn btn-lg nunito-family' style={{ borderRadius: "10px", border: "none" }}> SIGN UP</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className='footer nunito-family' style={{
                            position: 'absolute',
                            width: "67%",
                            bottom: "10px",
                            paddingTop: "1%"
                        }}>
                            <div className='log-op nunito-family'>or Login with</div>
                            <div className='log1-op nunito-family'
                                style={{
                                    display: "flex"
                                }} >
                                <img className='googleicon nunito-family' src="https://img.icons8.com/color/48/google-logo.png" />
                                <p style={{
                                    margin: " 4px 0px 0px 13px"
                                }}>Google</p>
                            </div>
                            <div className='log2-op'
                                style={{
                                    display: "flex"
                                }}
                            ><img className='googleicon nunito-family' src="https://img.icons8.com/color/48/facebook-new.png" />{" "}<p style={{
                                margin: " 4px 0px 0px 13px"
                            }}>Facebook</p></div>
                        </div>
                    </div>
                </div></form>

            <div className="mob-view nunito-family" 
            style={{
                background:"none"
            }}>
                <div className='card nunito-family' style={{
                    margin: " 0px",
                    border: "0px",
                    height: "auto",
                    width:"100%"
                }}>
                    <div className='CardHeader-mob nunito-family' style={{background:"white"}}>
                        <img src={zeraleads} alt='logo' style={{ width: "180px", height: "180px" }}></img>
                    </div>{loginshow === true ?
                        <div className='card-body-mob nunito-family' style={{ placeContent: "center", margin: "auto" }}>
                            <div className='input-fields-mob nunito-family' style={{ marginTop: "15%" }}>
                                <div className='username'>
                                <span><img src={usernamelogo}/></span>{" "}
                                    <input
                                        name="email"
                                        value={email}
                                        className='nunito-family'
                                        onChange={(e) => handleChange(e)}
                                        placeholder='Email'
                                        style={{ border: "none", outline: "none" }}
                                        required
                                    ></input>
                                </div>
                                <p style={{ fontSize: "12px", color: "red" }}>{emailerror ? "Enter Valid Email" : ""}</p>
                                <div className='userpassword nunito-family' style={{ paddingTop: "20px" }}>
                                    <span><img src={passwordlogo} /></span>{" "}
                                    <input
                                        name="password"
                                        value={password}
                                        className='nunito-family'
                                        onChange={(e) => handleChange(e)}
                                        placeholder='Password'
                                        style={{ border: "none", outline: "none" }}
                                        required
                                    ></input>
                                </div>
                                <p style={{ fontSize: "12px", color: "red" }}>{passworderror ? "Enter Valid Password" : ""}</p>
                            </div>
                            <div className='row nunito-family' style={{ margin: "0%" }}>
                                <div className='col-md-6 nunito-family' style={{ fontSize: "15px", color: "limegreen" }}>
                                    <p className='mobview-forgetpassword nunito-family' style={{ marginBottom: "2rem" }}><a>Forgot Password?</a></p>
                                </div>
                                <div className='col-md-6 button nunito-family'>
                                    <div className='mob-view-log-btn nunito-family' style={{
                                        width: "100% !important",
                                        textAlign: "center"
                                    }}>
                                        <button className='formbtn nunito-family' onClick={handlesubmit} >SIGN IN</button>
                                    </div>
                                </div>
                            </div>
                            <div className='mob-view-signup nunito-family' style={{ marginLeft: "0%", marginTop: "15%",justifyContent:"center",textAlign:"center" }}>
                                <p style={{ marginTop: "12%" }}>Dont have a Zera Lead account?<a href='#' style={{ textDecoration: "none" }} onClick={handlesignin}> Sign up</a></p>
                            </div>
                            <div className='log-opt nunito-family' style={{ textAlign: "center" }}><p>or Signin with</p></div>
                        </div>
                        :
                        <div className='card-body-mob-view nunito-family' style={{ alignItems: "center", justifyItems: "center" }}>
                            <div className='signupdetails-mob nunito-family' >
                                <div className='row nunito-family'>
                                    <div className="col-sm-3 nunito-family" style={{ paddingBottom: "2%" }}>
                                        <div className='namefield nunito-family' style={{
                                            borderBottom: "2px solid gray nunito-family", width: "80%", textAlign: "start"

                                        }}>
                                             <span><img src={namelogo} /></span>{" "}
                                            <input
                                                name="FirstName"
                                                className='form_input nunito-family'
                                                value={firstName}
                                                onChange={(e) => handleChange(e)}
                                                placeholder='First Name'
                                                style={{ border: "none", outline: "none" }}
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: "12px", color: "red" }}>{firstNameerror ? "Enter Valid First Name" : ""}</p>
                                    </div>
                                    <div className="col-sm-3 nunito-family" style={{ paddingBottom: "2%" }}>
                                        <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray", width: "80%", textAlign: "start" }}>
                                            <span><img src={namelogo} /></span>{" "}
                                            <input
                                                name="LastName"
                                                className='form_input nunito-family'
                                                value={lastname}
                                                onChange={(e) => handleChange(e)}
                                                placeholder='Last Name'
                                                style={{ border: "none", outline: "none" }}
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: "12px", color: "red" }}>{lastnameerror ? "Enter Valid Last Name" : ""}</p>
                                    </div>
                                </div>
                                <div className='row nunito-family'>
                                    <div className='col-sm-3 nunito-family' style={{ paddingBottom: "2%" }}>
                                        <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray", width: "80%", textAlign: "start" }}>
                                            <span><img src={emaillogo} height={21} width={21}/></span>{" "}
                                            <input
                                                name="signupuemail"
                                                className='form_input nunito-family'
                                                value={signupemail}
                                                onChange={(e) => handleChange(e)}
                                                placeholder='Email'
                                                style={{ border: "none", outline: "none" }}
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: "12px", color: "red" }}>{signupemailerror ? "Enter Valid Email" : ""}</p>
                                    </div>
                                </div>
                                <div className='row nunito-family'>
                                    <div className='col-sm-3 nunito-family' style={{ paddingBottom: "2%" }}>
                                        <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray", width: "80%", textAlign: "start" }}>
                                            <span><img src={passwordlogo} /></span>{" "}
                                            <input
                                                name="signuppassword"
                                                className='form_input nunito-family'
                                                value={signuppassword}
                                                onChange={(e) => handleChange(e)}
                                                placeholder='Password'
                                                style={{ border: "none", outline: "none" }}
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: "12px", color: "red" }}>{passwordmatch ? "Password Does Not Matched" : ""}</p>
                                        <p style={{ fontSize: "12px", color: "red" }}>{signuppassworderror ? "Enter Valid Password" : ""}</p>
                                    </div>
                                    <div className='col-sm-3 nunito-family' style={{ paddingBottom: "2%" }}>
                                        <div className='namefield nunito-family' style={{ borderBottom: "2px solid gray", width: "80%", textAlign: "start" }}>
                                            <span><img src={passwordlogo} /></span>{" "}
                                            <input
                                                name="confirm_password"
                                                className='form_input nunito-family'
                                                value={signupconformpassword}
                                                onChange={(e) => handleChange(e)}
                                                placeholder='Confirm Password'
                                                style={{ border: "none", outline: "none" }}
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: "12px", color: "red" }}>{passwordmatch ? "Password Does Not Matched" : ""}</p>
                                        <p style={{ fontSize: "12px", color: "red" }}>{signupconformpassworderror ? "Enter Valid Confirm Password" : ""}</p>
                                    </div>
                                </div>

                            </div>
                            <div className='checkbox acceptterms nunito-family' style={{ paddingBottom: "6%", paddingTop: "5%" }}>
                                <label style={{ alignItems: "center", marginLeft: "4px" }}><input
                                    type="checkbox" /> agree to the <a href='#' style={{ textDecoration: "none" }}> Terms of Service</a> and <a href='#' style={{ textDecoration: "none" }}>Privacy Notice</a></label>
                            </div>
                            <>
                                <button className='signup-btn nunito-family' style={{ borderRadius: "2px", border: "none" }}> SIGN UP</button>
                            </>
                            <div className='login-direct nunito-family'>
                                <p style={{ marginTop: "13%" }}>Already have Zera Leads account<a href='#' style={{ textDecoration: "none" }} onClick={handlelogin}> Sign in</a></p>
                            </div>
                            <div className='log-opt nunito-family' style={{ textAlign: "center" }}><p>or Signup with</p></div>
                        </div>}

                </div>
                <div className='footer-mob nunito-family' style={{ paddingTop: "5% ", paddingBottom: "6%", placeContent: "center", justifyContent: "center" }}>
                    <div className='row nunito-family' style={{ height: "40%", placeContent: "center", marginLeft: "8%", justifyContent: "center" }}>
                        {/* <div className="mob-footer" style={{ display: "flex", justifyContent: "center" }}> */}
                        <div className='col-6 col-mob nunito-family'
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }} >
                            <img className='googleicon nunito-family' src="https://img.icons8.com/color/48/google-logo.png" />
                            <p style={{
                                margin: " 4px 0px 0px 0px"
                            }}>Google</p>
                        </div>
                        <div className='col-6 nunito-family'
                            style={{
                                display: "flex",
                                justifyContent: "flex-start"
                            }}
                        ><img className='googleicon nunito-family' src="https://img.icons8.com/color/48/facebook-new.png" />{" "}
                            <p style={{
                                margin: " 4px 0px 0px 0px"
                            }}>Facebook</p>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;


